








/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import { UserDetails, UserHives } from '@/types';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class UserAdoptions extends Vue {
  @Prop(Object) readonly user!: UserDetails

  mounted() {
    console.log('UserAdoptions: mounted()'); // eslint-disable-line no-console
  }

  get userHives(): UserHives {
    return this.user.hives ?? {};
  }

  get hivesCount(): number {
    return this.user.hives && Object.keys(this.user.hives)
      ? Object.keys(this.user.hives).length : 0;
  }

  get text(): string {
    if (this.hivesCount === 0) return 'Nessuna';
    if (this.hivesCount === 1) return '1 arnia';
    return `${this.hivesCount} arnie`;
  }

  get textClass(): string {
    if (this.hivesCount === 0) return 'grey--text';
    return 'green--text';
  }
}
